<template>
  <div class="bg-white modal-card rounded">
    <div class="bg-primary-100 p-4 text-xl">Créer un domaine</div>
      <div class="grid grid-cols-4 overflow-y-auto p-4">
        <t-input-group
          class="col-span-4"
          label="Nom"
        >
          <t-input
            type="text"
            v-model="domain.label"
            placeholder="Nom du domaine"
            required
          />
        </t-input-group>
    </div>
    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-end">
      <t-button
        class="m-0"
        variant="secondary"
        @click="$parent.close()"
      >
        Annuler
      </t-button>
      <t-button @click.prevent="create">Créer</t-button>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'

export default {
  name: 'app-domain-create',
  data () {
    return {
      domain: {}
    }
  },
  methods: {
    create () {
      http.post('/domain', { ...this.domain }).then(res => {
        this.$emit('created', res.data)
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à créer ce domaine',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      }).finally(() => {
        this.$parent.close()
      })
    }
  }
}
</script>

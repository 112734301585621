<template>
  <div class="app-questions list">

    <div class="page-header">
      <div class="page-header--title">
        <h1 class="title">Domaines</h1>
        <h2 class="subtitle">Gestion des domaines</h2>
      </div>
      <div class="page-header--actions">
        <b-button color="success" type="is-success" @click="create">
          <i class="fas fa-plus-circle"></i> Créer un domaine
        </b-button>
      </div>
    </div>

    <div class="page-content">
      <section class="list page-content-box">
        <b-table
          :data="domains"
          :loading="loading"
          hoverable
          striped
          paginated
          draggable
          @click="goToDomain"
          @dragstart="dragstart"
          @drop="drop"
          @dragover="dragover"
          @dragleave="dragleave"
        >

          <template slot="empty">
            <section class="section" v-if="!loading">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="fas fa-frown"
                    size="is-large">
                  </b-icon>
                </p>
                <p>Aucun domaine.</p>
              </div>
            </section>
            <div v-if="loading">
              <b-skeleton animated></b-skeleton>
            </div>
          </template>

          <b-table-column field="slug" label="Slug" sortable v-slot="props">
            {{ props.row.slug }}
          </b-table-column>

          <b-table-column field="label" label="Domaine" sortable searchable v-slot="props">
            {{ props.row.label }}
          </b-table-column>

          <b-table-column field="faq" label="Modules" v-slot="props">
            <b-taglist>
              <b-tag :type="props.row.faq.is_activated === 1 ? 'is-success' : 'is-grey' ">
                FAQ
              </b-tag>
              <b-tag :type="props.row.map.is_activated === 1 ? 'is-success' : 'is-grey' ">
                Carte
              </b-tag>
              <b-tag :type="props.row.chat.is_activated === 1 ? 'is-success' : 'is-grey' ">
                Chat
              </b-tag>
              <b-tag :type="props.row.news.is_activated === 1 ? 'is-success' : 'is-grey' ">
                Actualités
              </b-tag>
              <!-- <b-tag :type="props.row.payment.is_activated === 1 ? 'is-success' : 'is-grey' ">
                Paiements
              </b-tag> -->
              <b-tag :type="props.row.survey.is_activated === 1 ? 'is-success' : 'is-grey' ">
                Sondages
              </b-tag>
              <b-tag :type="props.row.stat.is_activated === 1 ? 'is-success' : 'is-grey' ">
                Stats
              </b-tag>
            </b-taglist>
          </b-table-column>

        </b-table>
      </section>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import CreateDomain from './Modals/Create'

export default {
  name: 'app-domains-list',
  data () {
    return {
      loading: false,
      isModalOpen: false,
      modalContent: {},
      tableDragRow: null,
      tableDragRowIndex: null,
      domains: [],
      emptyDomain: {
        faq: {},
        map: {},
        chat: {},
        news: {},
        payment: {},
        stat: {},
        survey: {}
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    goToDomain (domain) {
      this.$router.push({ path: `/app/domain/${domain.uuid}` })
    },
    loadData () {
      this.loading = true
      http.get('/domain').then(({ data }) => {
        this.loading = false
        this.domains = data
      }).catch((err) => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des domaines',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },

    // Open Modal
    create () {
      this.$buefy.modal.open({
        parent: this,
        component: CreateDomain,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: (created) => {
            this.domains.push(created)
            this.$buefy.notification.open({
              duration: 4000,
              message: 'Le domaine a été créée avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    },

    // Table Drag / Drop
    dragstart (payload) {
      this.tableDragRow = payload.row
      this.tableDragRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      this.procedure.tasks.splice(payload.index, 0, this.procedure.tasks.splice(this.draggingRowIndex, 1)[0])
    }
  }
}
</script>

<style lang="scss">

</style>
